<template>
  <div class="blog-detail" v-if="entry">
    <div v-if="imageUrl !== ''"  class="title-image bg-mint_light">
      <img class="block mx-auto" :src="imageUrl" :alt="entry.attributes.Titelbild.data.attributes.alternativeText" :title="entry.attributes.Titelbild.data.attributes.caption" />
    </div>
    <Section id="blog-detail-1" background="mint_light">
      <div class="category-headline flex justify-between mb-4" :class="imageUrl === '' ? 'pt-5' : ''">
        <Headline type="h3" :text="entry.attributes.blog_kategorien.data.attributes.name" classes="small2 text-green_dark" />
        <Icons :type="entry.attributes.blog_kategorien.data.attributes.icon" classes="text-green_dark text-icons"/>
      </div>
      <Headline type="h1" :text="entry.attributes.name" classes="subline2 text-green_dark mb-5"></Headline>
      <Text v-if="entry.attributes.Teasertext" :text="entry.attributes.Teasertext" />
    </Section>
    <Section v-for="section of this.sections" :key="section.id" :background="section.background" :elements="section.elements" :id="section.id"/>
    <template v-if="similarEntries">
      <Section id="blog-detail-2" background="mint_light" v-if="similarEntries.length !== 0">
        <Headline type="h3" classes="subline3" :text="$t('message.moreEntries')" />
        <div class="category-headline flex justify-between mb-4">
          <Headline type="h3" :text="entry.attributes.blog_kategorien.data.attributes.name" classes="small2 text-green_dark" />
          <Icons :type="entry.attributes.blog_kategorien.data.attributes.icon" classes="text-green_dark text-icons"/>
        </div>
        <div class="similar-entries-container" >
          <Teasers v-for="similarEntry of similarEntries" :key="similarEntry.id" :title="similarEntry.attributes.name" :text="similarEntry.attributes.Teasertext" :href="'/discovery/' + similarEntry.attributes.permalink" :image="similarEntry.attributes.Titelbild.data ? similarEntry.attributes.Titelbild.data.attributes : null"  type="blog" />
        </div>
      </Section>
    </template>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import qs from "qs";
import ApiService from "@/services/apiService";
import eventService from "@/services/eventService";
import Meta from "@/services/metaService";

const Section = defineAsyncComponent(() => import('@/layout/Section'));
const Headline = defineAsyncComponent(() => import('@/components/Headline'));
const Icons = defineAsyncComponent(() => import('@/components/Icons'));
const Text = defineAsyncComponent(() => import('@/modules/Text'));
const Teasers = defineAsyncComponent(() => import('@/components/Teasers'));

export default {
  name: "BlogDetail",
  data() {
    return {
      imageUrl: '',
      entry: null,
      sections: [],
      similarEntries: null
    }
  },
  components: {
    Section,
    Headline,
    Icons,
    Text,
    Teasers
  },
  mounted() {
    eventService.$on('lang-switch', () => {
      this.fetchEntry();
      this.fetchSimilar();
    });
  },
  created() {
    this.rerender()
  },
  methods: {
    fetchMeta() {
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.populate= {ogImage: {populate: '*'}}
      let query = qs.stringify(queryOptions);
      ApiService.get(`allgemein?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          //let favicon = ApiService.getBaseUrl() + data.attributes.Favicon.data.attributes.url
          Meta.createMeta({name: 'name', content: 'description'}, {name: 'content', content: this.entry.attributes.Teasertext })
          Meta.createMeta({name: 'property', content: 'og:title'}, {name: 'content', content: this.entry.attributes.name})
          Meta.createMeta({name: 'property', content: 'og:description'}, {name: 'content', content: this.entry.attributes.Teasertext})
          Meta.createMeta({name: 'property', content: 'og:image'}, {name: 'content', content: this.imageUrl ? this.imageUrl : ApiService.getBaseUrl() + data.attributes.ogImage.data.attributes.url})
          Meta.createMeta({name: 'property', content: 'og:url'}, {name: 'content', content: window.location.href})
          Meta.createMeta({name: 'property', content: 'og:site_name'}, {name: 'content', content: data.attributes.Name})
          Meta.createMeta({name: 'name', content: 'robots'}, {name: 'content', content: 'index, follow'})
          Meta.createLink('canonical', window.location.href)
        }
        document.title = this.entry.attributes.name + " | " + data.attributes.Name
      })
    },
    fetchEntry() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.filters.permalink = {$eq: this.$route.params.slug};
      queryOptions.populate = {
        blog_kategorien: '*',
        Titelbild: '*',
        Content: {
          populate: '*'
        }
      };
      let query = qs.stringify(queryOptions);
      ApiService.get(`blogs?${query}`).then(res => {
        let data = res.data.data;
        if (data) {
          this.entry = data[0];
          this.content = this.entry.attributes.Content;
          this.getSections()
          if(this.entry.attributes.Titelbild.data) {
            this.imageUrl = ApiService.getBaseUrl() + this.entry.attributes.Titelbild.data.attributes.url;
          }
          this.fetchSimilar();
          this.fetchMeta();
        }
      });
    },
    fetchSimilar() {
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.filters.blog_kategorien = {id: {$eq: this.entry.attributes.blog_kategorien.data.id}};
      queryOptions.filters.id = {$ne: this.entry.id};
      queryOptions.pagination = {page: 1, pageSize: 3};
      queryOptions.sort = ['publishedAt:desc'];
      let query = qs.stringify(queryOptions);
      ApiService.get(`blogs?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          this.similarEntries = data;
        }
        this.$wait.end("page");
      })
    },
    getSections() {
      let sectionCount = -1;
      let sections = [];
      for (let element of this.content) {
        if(element["__component"] === "layout.section") {
          let section = {
            id: element.id,
            background: element.background,
            elements: []
          }
          sections.push(section);
          sectionCount++;
        } else {
          if(sectionCount >= 0) {
            sections[sectionCount].elements.push(element);
          }
        }
      }

      this.sections = sections;
    },
    getQueryOptions(locale) {
      return {
        populate: '*',
        sort: [],
        filters: {},
        locale: [locale],
      }
    },
    rerender(){
      this.$nextTick(() => {
        this.fetchEntry()
      })
    }
  },
  unmounted() {
    Meta.destroyMeta({name: 'name', content: 'description'})
    Meta.destroyMeta({name: 'name', content: 'robots'})
    Meta.destroyMeta({name: 'property', content: 'og:title'})
    Meta.destroyMeta({name: 'property', content: 'og:description'})
    Meta.destroyMeta({name: 'property', content: 'og:image'})
    Meta.destroyMeta({name: 'property', content: 'og:url'})
    Meta.destroyMeta({name: 'property', content: 'og:site_name'})
    Meta.destroyLink('canonical')
  }
}
</script>

<style scoped lang="scss">
  .blog-detail {

    .title-image {

      margin-top: -1.25rem;
      img {
        max-width: var(--section-width);
      }
    }

    .section {
      &:first-child {
        margin-top: 0;
        padding-top: 1.25rem;

        .section-inner {

          position:static;
          margin-bottom: 0;
        }
      }
    }
  }
</style>

<style lang="scss">
  .similar-entries-container {
    .teaser {

      .teaser-title {

        color: var(--green-dark);
      }
    }
  }
</style>
