const Meta = {

    createMeta(type = {}, content = {}) {

        const meta = document.createElement('meta');
        meta.setAttribute(type.name, type.content);
        meta.setAttribute(content.name, content.content);
        this.tagElement(meta)

    },

    createLink(rel = '', href = '') {

        const link = document.createElement('link');
        link.setAttribute('rel', rel);
        link.setAttribute('href', href);
        this.tagElement(link)
    },

    destroyMeta(type = {}) {
        document.querySelector('meta[' + type.name + '="' + type.content + '"]').remove()

    },

    destroyLink(rel= '') {
        document.querySelector('link[rel="' + rel + '"]').remove();
    },

    tagElement(meta) {
        document.getElementsByTagName('head')[0].appendChild(meta);

    }
}


export default Meta
